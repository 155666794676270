<template>
    <b-modal ref="modal" title="Dodaj uporabnika" centered no-close-on-backdrop @cancel="makeEmptyObject" @close="makeEmptyObject" @hide="makeEmptyObject">
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Ime">
                    <validation-provider #default="{errors}" name="Ime" rules="required|min:1|max:50">
                        <b-form-input v-model="addUserObject.firstname"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Priimek">
                    <validation-provider #default="{errors}" name="Priimek" rules="required|min:1|max:50">
                        <b-form-input v-model="addUserObject.lastname"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <validation-provider #default="{errors}" name="Email" rules="required|email">
                        <b-form-input v-model="addUserObject.email"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Geslo">
                    <validation-provider #default="{errors}" name="Geslo" rules="required|password">
                        <b-form-input v-model="addUserObject.password" type="password"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer="{ hide }">
            <b-button variant="outline-primary" @click="hide">
                Zapri
            </b-button>
            <b-button variant="primary" @click="add">
                Dodaj
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import {BButton, BModal, BFormGroup, BFormInput} from 'bootstrap-vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {email, required, min, max, password} from '@core/utils/validations/validations'

    export default {
        components: {
            BModal,
            BButton,
            BFormGroup,
            BFormInput,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                addUserObject: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    password: ''
                },
                required,
                email,
                min,
                max,
                password
            }
        },
        methods: {
            show() {
                this.$refs.modal.show()
            },
            async add() {
                const isValid = await this.$refs.validation.validate()
                if (!isValid) return

                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.post('/api/private/v1/user/', this.addUserObject)
                    this.$refs.modal.hide()
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno')
                    this.makeEmptyObject()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri dodajanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            makeEmptyObject() {
                this.addUserObject = this.$options.data.call(this).addUserObject
            }
        }
    }
</script>
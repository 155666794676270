var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"title":"Dodaj uporabnika","centered":"","no-close-on-backdrop":""},on:{"cancel":_vm.makeEmptyObject,"close":_vm.makeEmptyObject,"hide":_vm.makeEmptyObject},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":"Ime"}},[_c('validation-provider',{attrs:{"name":"Ime","rules":"required|min:1|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addUserObject.firstname),callback:function ($$v) {_vm.$set(_vm.addUserObject, "firstname", $$v)},expression:"addUserObject.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Priimek"}},[_c('validation-provider',{attrs:{"name":"Priimek","rules":"required|min:1|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addUserObject.lastname),callback:function ($$v) {_vm.$set(_vm.addUserObject, "lastname", $$v)},expression:"addUserObject.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addUserObject.email),callback:function ($$v) {_vm.$set(_vm.addUserObject, "email", $$v)},expression:"addUserObject.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Geslo"}},[_c('validation-provider',{attrs:{"name":"Geslo","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.addUserObject.password),callback:function ($$v) {_vm.$set(_vm.addUserObject, "password", $$v)},expression:"addUserObject.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
var hide = ref.hide;
return [_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":hide}},[_vm._v(" Zapri ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.add}},[_vm._v(" Dodaj ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }